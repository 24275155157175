<template>
    <div>
        <div class="my-profile text-center">
            <div class="cimg">
                <img
                    :src="profile.info 
                    && profile.info.profile_image 
                    ? profile.info.profile_image  : ''"
                    alt=""
                />
            </div>
            <div class="name text-center p-3">
                <router-link to="/my-page">
                    <h5>{{
                            profile.info ?  profile.info.name : 'no nickname'
                        }}</h5>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data(){
        return {
            profile: {}
        }
    },
    created(){
        this.profile = this.user;
    },
    computed: {
        ...mapGetters({
            user: "member/getUser"
        }),
    },
    watch: {
        user(data){
            this.profile = data;
        }
    }
};
</script>