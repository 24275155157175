import { render, staticRenderFns } from "./master.vue?vue&type=template&id=324862a0&scoped=true"
import script from "./master.vue?vue&type=script&lang=js"
export * from "./master.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324862a0",
  null
  
)

export default component.exports