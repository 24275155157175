<template>
    <div class="main">
        <div class="row m-0">
            <div class="col-12 p-0">
                <router-view></router-view>
            </div>
            <div class="col-12 p-0 menu-profile">
                <div class="mb-2">
                    <Profile />
                </div>
                <div>
                    <MenuProfile />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuProfile from "./menu-profile.vue";
import Profile from "./profile.vue";
export default {
    components: {
        Profile,
        MenuProfile,
    },
}
</script>
<style scoped>

</style>