<template lang="html">
    <div>
        <h2 class="title p-2 text-center font-weight-bold" >管理機能</h2>
        <div class="container menus">
            <div class="row m-0 profiles">
                <div
                    class="list p-0 "
                    v-for="(item, key) in page_utility_config"
                    :key="key"
                >
                    <div class="card">
                        <router-link
                            to="/my-page/profile"
                            v-if="item.name == 'teacher-profile'">
                            <div class="card-body">
                                {{ $t(item.page_name) }}
                            </div>
                        </router-link>
                        <router-link :to="{name: item.name}" v-else>
                            <div class="card-body text-left">
                                {{ $t(item.page_name) }}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import * as myPageMenu from '@/api/my_page_menu';
export default {
    data() {
        return {
            member_id: 0,
            page_utility_config: [],
        };
    },
    computed:{
        ...mapGetters({
            user: "member/getUser"
        })
    },
    created(){
        this.handleMenu(this.user);
    },
    methods: {
        handleMenu(val){
            if(val.teacher
                && val.teacher.status == "active"){
                this.page_utility_config = myPageMenu.default.teacher
            }else{
                this.page_utility_config = myPageMenu.default.student
            }
            if(val.info)
                this.member_id = val.info.member_id;
        }
    },
    watch: {
        user(val){
            this.handleMenu(val);
        }
    }
}
</script>
<style scoped>
.profiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
}
.card {
    display: block;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 3px solid #FDE2B7;
    border-radius: 8px;
    height: 55px;
    opacity: 1;
    align-items: center;
    justify-content: center;
    margin: 3px;
}

.card a {
    color: var(--unnamed-color-f39800);
    text-align: center;
    font: normal normal normal 14px/24px var(--unnamed-font-family-hiragino-sans);
    letter-spacing: 0.14px;
    opacity: 1;
    font-weight: 700;
    white-space: nowrap;
}

.card-body{
    min-height: 55px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    padding: 10px;
}
.min-height{
    min-height: 90px !important;
}

.menus {
    padding: 5px;
    background: #FFF5E5 0% 0% no-repeat padding-box;
    border-radius: 12px;
}
.list {
    width: 100%;
    text-align: center;
    margin: 0px 0px;
}
.title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 24px/var(--unnamed-line-spacing-24) var(--unnamed-font-family-hiragino-sans);
    color: var(--unnamed-color-222222);
    text-align: center;
    letter-spacing: 0.24px;
    color: #222222;
    font-weight: 700;
    text-align: center;
}
</style>
